<template>
  <div>
    <!-- card de pedidos -->
    <Header
      v-if="items.length"
      button="Finalizar"
      button2="Continuar comprando"
      title="Confira seu pedido"
      subtitle="Verifique a quantidade de cada item do seu carrinho antes de finalizar o pedido."
      @click="verifyCart()"
      @click2="$router.push('/produtos')"
    >
      <div class="mt-5 px-1">
        <ul class="divide-y divide-gray-200">
          <li v-for="(item, index) in items" :key="index">
            <div class="grid grid-cols-5 gap-4 py-4">
              <div class="col-span-5 flex items-center" :class="item.multiversion ? 'sm:col-span-3' : 'sm:col-span-4'">
                <img class="h-12 w-12 rounded" :src="item.image" :alt="item.title">
                <div class="px-4">
                  <p class="text-sm font-medium text-primary">{{ item.title }}</p>
                  <p class="mt-1 flex items-center text-sm text-gray-500">
                    <span class="truncate font-medium">{{ item.price | currency }}</span>
                  </p>
                </div>
              </div>
              <div v-if="item.multiversion" class="col-span-5 sm:col-span-1">
                <Select v-model="item.config" name="config" empty="Versão" :values="['12V', '24V']" />
              </div>
              <div class="my-auto col-span-5 sm:col-span-1 flex rounded-md shadow-sm" style="height: min-content">
                <div class="relative flex items-stretch flex-grow focus-within:z-10">
                  <input v-model="item.amount" @change="changeItem(item.id, item.amount)" type="number" name="amount" id="amount" class="focus:ring-primary focus:border-primary block w-full rounded-none rounded-l-md sm:text-sm border-gray-300">
                </div>
                <button @click="exclusion.id = item.id, exclusion.image = item.image, $modal.show('delete')" class="-ml-px relative inline-flex items-center space-x-2 px-4 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="pt-5 border-t border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Total</h3>
        <div class="mt-3 sm:mt-0 sm:ml-4">
          <span class="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-gray-600">{{ priceTotal | currency }}</span>
        </div>
      </div>
    </Header>
    <!-- card de carrinho vazio -->
    <div v-else class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
        <div class="sm:flex sm:items-center sm:justify-between">
          <h3 class="inline-flex text-lg leading-6 font-medium text-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            Ops, seu carrinho está vazio...
          </h3>
          <div class="mt-3 sm:mt-0 sm:ml-4">
            <button @click="$router.push('/produtos')" type="button" class="w-full inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              Ver Produtos
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal de finalização de pedido -->
    <t-modal name="confirm" class="z-45">
      <p class="text-base font-medium text-gray-800">Finalizar Compra</p>
      <form @submit.prevent class="mt-4">
        <p class="font-medium mb-3 text-sm text-gray-700">Confirme algumas informações antes de finalizar:</p>
        <div class="grid grid-cols-5 gap-2">
          <div v-if="$store.state.user.Document && $store.state.user.Document.length > 14" class="col-span-5">
            <Select name="use" label="Uso do Produto" :values="['Revenda', 'Industrialização', 'Uso e Consumo']" v-model="confirm.use" />
          </div>
          <div class="col-span-5">
            <Select name="payment" label="Método de pagamento" :values="['PIX', 'Transferência', 'Mercado Pago']" v-model="confirm.payment" />
          </div>
          <div class="col-span-5 flex items-end">
            <Select name="address" label="Endereço para entrega" :values="addresses.map(item => item.Name)" v-model="confirm.address" class="w-full" @change="calculateFrete()" />
            <button @click="$router.push('/conta')" type="submit" class="ml-2 p-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
        <fieldset v-if="pac.value || sedex.value">
          <div class="relative bg-gray-50 rounded-md -space-y-px mt-4">
            <label v-if="pac.value" class="border-gray-300 relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3" :class="sedex.value ? 'rounded-tl-md rounded-tr-md' : 'rounded-md'">
              <div class="flex items-center text-sm">
                <input v-model="confirm.frete" type="radio" name="pac" value="PAC" class="h-4 w-4 text-primary border-gray-300 focus:ring-primary">
                <span class="ml-3 font-medium text-gray-900">PAC</span>
              </div>
              <p class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                <span class="font-medium" :class="confirm.frete === 'PAC' ? 'text-primarylight' : 'text-gray-900'">{{ pac.value | currency }}</span>
              </p>
              <p class="text-gray-500 ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">{{ pac.days }} dias<span class="font-bold ml-0.5">*</span></p>
            </label>
            <label v-if="sedex.value" class="border relative border-gray-300 p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3" :class="pac.value ? 'rounded-bl-md rounded-br-md' : 'rounded-md'">
              <div class="flex items-center text-sm">
                <input v-model="confirm.frete" type="radio" name="sedex" value="SEDEX" class="h-4 w-4 text-primary border-gray-300 focus:ring-primary">
                <span class="ml-3 font-medium text-gray-900">Sedex</span>
              </div>
              <p class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                <span class="font-medium" :class="confirm.frete === 'SEDEX' ? 'text-primarylight' : 'text-gray-900'">{{ sedex.value | currency }}</span>
              </p>
              <p class="text-gray-500 ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">{{ sedex.days }} dias<span class="font-bold ml-0.5">*</span></p>
            </label>
          </div>
        <p class="mt-1 text-sm text-gray-500"><span class="font-bold ml-0.5">*</span> Prazo após a postagem do pedido</p>
        </fieldset>
        <button @click="finishCart()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Confirmar
        </button>
      </form>
    </t-modal>
    <!-- modal com instruções de pagamento -->
    <t-modal name="payment" class="z-45">
      <p class="text-base font-medium text-gray-800">Realizar Pagamento</p>
      <form @submit.prevent class="mt-4">
        <p class="font-medium mb-3 text-sm text-gray-700">Siga as instruções de pagamento abaixo para finalizar seu pedido.</p>
        <div v-if="confirm.payment === 'PIX'" class="border rounded-lg p-3 bg-gray-50">
          <div @click="payment.pix = !payment.pix" class="flex justify-between w-full">
            <p class="font-semibold text-gray-700 text-sm">PIX</p>
            <svg v-if="payment.pix" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </div>
          <div v-if="payment.pix" class="text-center font-medium text-gray-800">
            <img class="h-40 mx-auto" src="@/assets/qrcode.png" alt="QR Code">
            <p>CNPJ: 12.850.588/0001-51</p>
            <p class="text-gray-600 my-2">Valor: {{ priceTotalAux | currency }}</p>
            <p class="text-xs text-primary">O resumo do seu pedido está sendo enviado ao seu email.</p>
          </div>
        </div>
        <div v-if="confirm.payment === 'Transferência'" class="mt-4 border rounded-lg p-3 bg-gray-50">
          <div @click="payment.transf = !payment.transf" class="flex justify-between w-full">
            <p class="font-semibold text-gray-700 text-sm">Transferência</p>
            <svg v-if="payment.transf" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </div>
          <div v-if="payment.transf" class="text-center font-medium text-gray-800">
            <p class="text-gray-600 my-2">Valor: {{ priceTotalAux | currency }}</p>
            <p>341 - Banco Itáu</p>
            <p>Agência: 4009</p>
            <p>Conta Corrente: 36580-2</p>
            <p class="text-gray-600 mt-2">VW Soluções Comércio e Serviços Ltda ME</p>
            <p class="text-gray-600">CNPJ: 12.850.588/0001-51</p>
            <p class="text-gray-600">I.E.: 795.108.102.118</p>
            <p class="mt-3 text-xs text-primary">O resumo do seu pedido está sendo enviado ao seu email.</p>
          </div>
        </div>
        <div v-if="!['PIX', 'Transferência'].includes(confirm.payment)" class="mt-4 border rounded-lg p-3 bg-gray-50 text-sm text-center">
          <p class="mb-2 text-gray-800 font-medium">Seu pedido está sendo processado!</p>
          <p class="text-gray-600">Em breve, você receberá em seu email as instruções para finalização do pagamento.</p>
        </div>
        <p class="mt-4 font-medium text-xs text-gray-500 text-center">Caso deseje, nos encaminhe o comprovante de pagamento pelo Whatsapp (isso poderá agilizar o processamento do seu pedido).</p>
        <p @click="sendWhatsapp()" class="font-medium mt-2 text-xs text-primary text-center cursor-pointer">Abrir Whatsapp</p>
        <button @click="$router.push('/conta')" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Finalizar
        </button>
      </form>
    </t-modal>
    <!-- modal para deletar produto -->
    <t-modal name="delete" class="z-45">
      <p class="text-base font-medium text-gray-800">Excluir Produto</p>
      <form @submit.prevent class="text-center">
        <div class="flex justify-center my-8">
          <img class="w-24 h-24 rounded-lg" :src="exclusion.image" />
        </div>
        <p>Tem certeza que deseja excluir esse produto do seu carrinho?</p>
        <div class="mt-4 flex">
          <button @click="$modal.hide('delete')" type="submit" class="w-full inline-flex justify-center py-2 px-4 border border-primary shadow-sm text-sm font-medium rounded-md text-primary bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Manter</button>
          <button @click="deleteItem(exclusion.id), $modal.hide('delete')" type="submit" class="ml-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Apagar</button>
        </div>
      </form>
    </t-modal> 
  </div>
</template>

<script>
import idb from '@/idb'
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { auth, listingsColl, ordersColl, usersColl } from '@/firebase'

export default {
  components: { 
    Select: () => import('@/components/form/select'),
    Header: () => import('@/components/layout/header') 
  },

  data() {
    return {
      user: auth.currentUser,
      cart: {},
      items: [],
      products: [],
      addresses: [],
      priceTotalAux: 0,
      pac: { value: 0, days: 0 },
      sedex: { value: 0, days: 0 },
      exclusion: { id: '', image: '' },
      payment: { pix: true, transf: true },
      confirm: { payment: '', use: '', address: '', frete: '' },
    }
  },

  computed: {
    priceTotal: function() {
      return this.items.reduce((ac, cr) => ac + (cr.price * cr.amount), 0) 
    }
  },

  async mounted() { await this.getProducts(), await this.getCart(), this.getAddresses() },

  methods: {
    async getProducts() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Products').get().then(result => this.products = result.data().available).catch(() => this.$toast.error('Falha ao obter os produtos, tente novamente'))
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async getCart() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await idb.getFields().then(result => this.cart = result).catch(() => this.$toast.error('Falha ao carregar o carrinho, tente novamente'))
      this.items = this.products.filter(item => Object.keys(this.cart).includes(item.id) && !item.unavailable && !item.invisible).map(item => ({...item, amount: this.cart[item.id], config: ''}))
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async getAddresses() {
      if (!auth.currentUser) { return }
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await usersColl.doc(auth.currentUser.uid).collection('Addresses').get().then(result => { this.addresses = result.docs.map(item => item.data()), this.addresses.push({ Name: 'Retirar em mãos' }) }).catch(() => this.$toast.error('Erro ao obter seus endereços, tente novamente'))
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async changeItem(id, amount) {
      if (isNaN(amount)) { this.$toast.error('Apenas valores númericos são aceitos'); return }
      if (!amount || amount == 0) { amount = 1 }

      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await idb.saveField(id, amount).catch(() => { this.$toast.error('Falha ao carregar o carrinho, tente novamente') }), this.getCart()
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async deleteItem(id) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await idb.deleteField(id).then(() => this.$toast.success('Produto excluído com sucesso')).catch(() => this.$toast.error('Falha ao carregar o carrinho, tente novamente')), this.getCart()
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async calculateFrete() { 
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      if (this.confirm.address === 'Retirar em mãos') { this.confirm.frete = 'Retirada', this.sedex.value = 0, this.pac.value = 0 } else {
        let cep = this.addresses.find(item => item.Name === this.confirm.address)
        if (!cep) { this.$toast.error('Falha ao obter o cep, tente novamente') }

        // let token
        // await auth.currentUser.getIdToken().then((result) => token = result)
        // await axios.post('https://southamerica-east1-vw-solucoes.cloudfunctions.net/frete', { action: 'GET_FRETE', cep: cep.Cep.replace('-', ''), peso: this.items.reduce((ac, cr) => ac + (cr.amount * (cr.weight || 0)), 0), valor: this.priceTotal }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }})
        // .then(result => {
        //   this.sedex.value = result.data.sedex.valor
        //   this.sedex.days = result.data.sedex.prazo
        //   this.pac.value = result.data.pac.valor
        //   this.pac.days = result.data.pac.prazo
        // })
        // .catch(() => this.$toast.error('Falha ao obter o frete, tente novamente'))

        await axios.get(`https://cepcerto.com/ws/json-frete-opcional/13070780/${cep.Cep.replace('-', '')}/${this.items.reduce((ac, cr) => ac + (cr.amount * (cr.weight || 0)), 0)}/10/20/30/${this.priceTotal}/0/0/de8b90fa772eb0a24a394d0bc3a8bd6e062d5edc`)
        .then(result => {
          this.sedex.value = parseFloat((result.data[1].valorTotalSedex || '').replace(',', '.') || 0)
          this.sedex.days = result.data[1].prazoSedex
          this.pac.value = parseFloat((result.data[2].valorTotalPac || '').replace(',', '.') || 0)
          this.pac.days = result.data[2].prazoPac
        })
        .catch(() => this.$toast.error('Falha ao obter o frete, tente novamente'))
      }
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    verifyCart() {
      if (!this.user) { this.$modal.show('login'); return }
      if (!this.$store.state.user?.Document) { this.$modal.show('complete'); return } 
      if (this.items.some(i => i.multiversion && !i.config)) { return this.$toast.error('Selecione a versão dos produtos') }
      
      this.$modal.show('confirm')
    },

    async finishCart() {
      if (!this.confirm.frete) { this.$toast.error('Selecione uma modalidade de frete'); return false }
      if (!this.confirm.address) { this.$toast.error('Selecione um endereço para entrega'); return false }
      if (this.$store.state.user?.Document.length > 14 && !this.confirm.use) { this.$toast.error('Selecione o uso dos produtos'); return false }
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await ordersColl.add({
        items: this.items.map(item => { return { title: item.title, price: item.price, image: item.image, amount: item.amount, version: item.config || '' } }),
        createdAt: new Date().toISOString(),
        id: auth.currentUser.uid,
        name: this.$store.state.user.Name || 'Desconhecido',
        email: this.$store.state.user.Email, 
        use: this.confirm.use || '', 
        payment: this.confirm.payment || 'Desconhecido',
        frete: { name: this.confirm.frete, value: this.confirm.frete === 'SEDEX' ? this.sedex.value : this.pac.value },
        address: this.confirm.address === 'Retirar em mãos' ? 'Retirar em mãos' : this.addresses.find(item => item.Name === this.confirm.address),
      })
      .then(async(result) => { 
        this.priceTotalAux = this.priceTotal + (this.confirm.frete === 'SEDEX' ? this.sedex.value : this.pac.value)

        let token
        await auth.currentUser.getIdToken().then((result) => token = result)
        await axios.post('https://southamerica-east1-vw-solucoes.cloudfunctions.net/email', { 
          action: 'SEND_EMAIL',
          order: result.id, 
          email: this.$store.state.user.Email, 
          items: this.items.map(item => { return { title: item.title, price: this.$options.filters.currency(item.price), image: item.image, amount: item.amount, total: this.$options.filters.currency(item.price * item.amount), version: item.config || '' } }),
          user: { email: this.$store.state.user.Email, name: this.$store.state.user.Name, document: `${this.$store.state.user?.Document.length === 18 ? 'CNPJ:' : 'CPF:'} ${this.$store.state.user?.Document}`, phone: `Tel: ${this.$store.state.user.Phone}`, ...(this.$store.state.user.Nascimento && { nasc: `Nascimento: ${this.$store.state.user.Nascimento}` }), ...(this.$store.state.user.Juridico && { fant: `Nome Fantasia: ${this.$store.state.user.Juridico?.NameFantasia}`, ie: `I.E.: ${this.$store.state.user.Juridico?.Ie}`, endComercial: this.$store.state.user.Juridico?.Address }) },
          pagamento: this.confirm.payment,
          frete: { name: this.confirm.frete, valor: this.$options.filters.currency(this.confirm.frete === 'SEDEX' ? this.sedex.value : this.pac.value) }, 
          total: this.$options.filters.currency(this.priceTotalAux), 
          peso: this.items.reduce((ac, cr) => ac + (cr.amount * (cr.weight || 0)), 0),
          endereco: this.confirm.address === 'Retirar em mãos' ? { Street: 'Retirar em mãos' } : this.addresses.find(item => item.Name === this.confirm.address),
          ...(this.confirm.use && {use: this.confirm.use}),
        }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }})

        this.$modal.hide('confirm')
        this.$modal.show('payment')
        this.$toast.success('Pedido gerado com sucesso')
        idb.clearDb().then(() => { this.getCart() })
      }) 
      .catch(() => { this.$toast.error('Falha ao gerar pedido, tente novamente') }) 
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    sendWhatsapp() { window.open('https://api.whatsapp.com/send?phone=5519999987126', '_blank') }
  },

  filters: {
    currency(val) {
      var currency = parseFloat(val)
      return currency.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
    }
  },

  directives: { mask }
}
</script>